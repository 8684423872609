import { Body, H3, SemiBold } from 'atomic'
import * as React from 'react'
import ImageWithFold, {
  FoldPosition
} from '../../../components/mol.image-with-fold/image-with-fold.component'
import {
  TimelineEventCardImageWrapper,
  TimelineEventCardSpacer,
  TimelineEventCardStyled,
  TimelineEventCardTextSeparator,
  TimelineEventCardTextWrapper
} from './timeline-event-card.style'

export type ImgPosition = 'left' | 'right'
export interface TimelineEventCardProps {
  imgixSrc: string
  imgDescription: string
  imgPosition: ImgPosition
  title: string
  contentHTML: string
}

const TimelineEventCard: React.FunctionComponent<TimelineEventCardProps> = React.memo(props => (
  <TimelineEventCardStyled>
    <TimelineEventCardSpacer imgPosition={props.imgPosition} />
    <TimelineEventCardTextWrapper imgPosition={props.imgPosition}>
      <H3>{props.title}</H3>
      <Body>
        <div dangerouslySetInnerHTML={{ __html: props.contentHTML }} />
        <TimelineEventCardTextSeparator />
        <SemiBold>Na imagem: </SemiBold>
        {props.imgDescription}
      </Body>
    </TimelineEventCardTextWrapper>

    <TimelineEventCardImageWrapper imgPosition={props.imgPosition}>
      <ImageWithFold
        imgixSrc={props.imgixSrc}
        imgAlt={props.imgDescription}
        imgAspectRatio={getImageAspectRatio(props.imgPosition)}
        foldPosition={getFoldPosition(props.imgPosition)}
      />
    </TimelineEventCardImageWrapper>
  </TimelineEventCardStyled>
))

const getImageAspectRatio = (imgPosition: ImgPosition) => {
  switch (imgPosition) {
    case 'left':
      return 1
    case 'right':
      return 0.66
    default:
      return 1
  }
}
const getFoldPosition = (imgPosition: ImgPosition): FoldPosition => {
  switch (imgPosition) {
    case 'left':
      return 'top-left'

    case 'right':
      return 'top-right'

    default:
      return 'top-left'
  }
}

export default TimelineEventCard

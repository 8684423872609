import { Col, Grid, H2, Row } from 'atomic'
import { graphql } from 'gatsby'
import React from 'react'
import LargeSeparatorRow from 'atomic/atm.large-separator-row/large-separator-row.component'
import { SEO } from 'site/src/components/legacy/mol.seo/seo.component'
import { ChapterNavigation } from 'site/src/components/mol.chapter-navigation/chapter-navigation.component'
import IndexLayout from 'site/src/components/org.layout/layout.component'
import TitleWithBreadcrumbRow from 'site/src/components/org.title-with-breadcrumb-row/title-with-breadcrumb-row.component'
import { ClientDoctorSelectContext } from 'site/src/context/client-doctor-select'
import { CosmicjsInformacoesEstaticas } from 'site/src/data/graphql/graphql-types'
import { PageProps } from 'site/src/utils/local-types'
import { appPaths } from 'utils/path'
import { getClientAndDoctorUrl } from 'utils/url'
import { ChapterIndex } from '../diagnostic-guide/diagnostic-manual/diagnostic-manual-detail'
import { RelatedPagesProps } from 'site/src/modules/about/about-detail'
import CompleteTimeLine from 'site/src/modules/about/component/complete-timeline.component'

export interface HistoricoPageProps {
  relatedPages: RelatedPagesProps[]
}

const HistoriaPage: React.FunctionComponent<PageProps<HistoricoPageProps>> = props => {
  const [clientPath, doctorPath] = getClientAndDoctorUrl(props.location)
  const breadcrumbAdditionalDictionary = {}
  breadcrumbAdditionalDictionary[appPaths.history.path.clientUrl] = appPaths.history.name
  const staticInfo = props.data.cosmicjsInformacoesEstaticas as CosmicjsInformacoesEstaticas
  const seoData = staticInfo.metadata.seo

  const navIndex: ChapterIndex[] = props.pageContext.relatedPages

  return (
    <ClientDoctorSelectContext.Provider value={{ clientUrl: clientPath, doctorUrl: doctorPath }}>
      <IndexLayout location={props.location}>
        <SEO
          socialMedia={{
            canonicalUrl: `${props.data.site.siteMetadata.siteUrl}${props.location.pathname}${props.location.search}`,
            title: seoData.titulo,
            image: seoData.imagem.url,
            imageAlt: seoData.imagemAlternativa,
            description: seoData.descricao
          }}
        />

        <Grid>
          <TitleWithBreadcrumbRow
            title={props.data.cosmicjsInformacoesEstaticas.title}
            addtionalDictionary={breadcrumbAdditionalDictionary}
          >
            <div dangerouslySetInnerHTML={{ __html: props.data.cosmicjsInformacoesEstaticas.content }} />
          </TitleWithBreadcrumbRow>
        </Grid>
        <CompleteTimeLine
          items={props.data.allCosmicjsSobreFleuryHistoria.nodes
            .sort((a, b) => parseInt(a.title, 0) - parseInt(b.title, 0))
            .map(node => ({
              year: node.title,
              imgixSrc: node.metadata.imagem.imgix_url,
              imgDescription: node.metadata.imagemAlternativa,
              contentHTML: node.metadata.descricao,
              title: node.metadata.titulo
            }))}
        />
        <Grid>
          <Row mb>
            <Col xs={12} sm={4}>
              {/* // TODO: COSMIC */}
              <H2 cell>Saiba mais sobre nós</H2>
              <ChapterNavigation chapterIndex={navIndex} currentPath={props.location.pathname} hideNav />
            </Col>
          </Row>
          <LargeSeparatorRow />
        </Grid>
      </IndexLayout>
    </ClientDoctorSelectContext.Provider>
  )
}

export default HistoriaPage

export const query = graphql`
  query HistoricoDetail {
    site {
      siteMetadata {
        siteUrl
      }
    }

    allCosmicjsSobreFleuryHistoria {
      nodes {
        id
        title
        metadata {
          titulo
          descricao
          imagemAlternativa
          imagem {
            url
            imgix_url
          }
        }
      }
    }

    cosmicjsInformacoesEstaticas(slug: { eq: "historia" }) {
      title
      content
      metadata {
        seo {
          titulo
          descricao
          imagemAlternativa
          imagem {
            url
            imgix_url
          }
        }
      }
    }
  }
`

import { H1Style, HDisplayStyle } from 'atomic/legacy/atm.typography'
import { Color, Spacing } from 'atomic'
import styled from 'styled-components'

export const DecadeTimeLineStyled = styled.ol`
  background: ${Color.White};
  margin: 0;
  padding: 0;

  border-bottom: 1px solid ${Color.Gray};
  margin-top: 0;
  @media all and (min-width: 48em) {
    margin-top: ${Spacing.Medium};
    border-bottom: 0;
  }
`
export interface DecadeTimeLineItemStyledProps {
  selected: boolean
}

export const DecadeTimeLineItemStyled = styled.li`
  list-style: none;

  margin-top: ${Spacing.Medium};
  margin-bottom: 0;
  @media all and (min-width: 48em) {
    margin-top: 0;
    margin-bottom: ${Spacing.Medium};
  }
`

export const DecadeTimeLineItemTextStyled = styled.span`
  ${(props: DecadeTimeLineItemStyledProps) => (props.selected ? HDisplayStyle : H1Style)}
  color: ${(props: DecadeTimeLineItemStyledProps) =>
    props.selected ? Color.GrayXDark : Color.Gray};
  cursor: pointer;
  :hover {
    color: ${Color.GrayXDark};
  }
`

import { Color } from 'atomic'
import styled from 'styled-components'
import { FoldPosition } from './image-with-fold.component'

export const ImageWithFoldStyled = styled.div<{ foldPosition: FoldPosition }>`
  position: relative;
  width: 100%;

  :before {
    content: '';
    position: absolute;
    ${props => getFoldPosition(props.foldPosition)}
    margin: 0;

    /** https://css-tricks.com/the-shapes-of-css/ */
    width: 0;
    height: 0;
    ${props => getFoldShape(props.foldPosition)}
  }
`
const getFoldPosition = (foldPosition: FoldPosition) => {
  switch (foldPosition) {
    case 'top-left':
      return `
      top: 0;
      left: 0;`
    case 'top-right':
      return `
        top: 0;
        right: 0;`

    default:
      return ``
  }
}

const getFoldShape = (foldPosition: FoldPosition) => {
  switch (foldPosition) {
    case 'top-left':
      return `
      border-bottom: 2rem solid transparent;
      border-left: 2rem solid ${Color.White};`
    case 'top-right':
      return `
      border-bottom: 2rem solid transparent;
      border-right: 2rem solid ${Color.White};`

    default:
      return ``
  }
}

import { TopStickyWrapper } from '@root/src/components/atm.wrapper/wrapper.component'
import { scrollToId } from '@root/src/utils/scroll'
import { Col, Grid, HeaderHeight, Row, Separator } from 'atomic'
import * as React from 'react'
import { isDesktopScreenSize } from 'utils/browser'
import { debounce } from 'utils/debounce/debounce'
import DecadeTimeLine, { DecadeTimeLineRef } from './decade-timeline.component'
import TimelineEvent from './timeline-event.component'

interface CompleteTimeLineItem {
  year: number
  imgixSrc: string
  imgDescription: string
  title: string
  contentHTML: string
}

interface CompleteTimeLineProps {
  items: CompleteTimeLineItem[]
}

const CompleteTimeLine: React.FunctionComponent<CompleteTimeLineProps> = props => {
  const [currentYear, forceCurrentYear, handleItemVisibilityChange, decadeRef] = useYearVisibility(
    props
  )
  const handleDecadeClick = (year: number[]) => {
    const firstYearOnDecade = year[0]
    const id = getTimelineEventId(firstYearOnDecade)
    const headerHeight = isDesktopScreenSize() ? HeaderHeight.Desk : HeaderHeight.Mobile
    // the timeline header height was measued in runtime
    const decadeTimelineHeaderHeight = isDesktopScreenSize() ? 0 : 92
    const scrollOffset = headerHeight + decadeTimelineHeaderHeight
    forceCurrentYear(firstYearOnDecade)
    scrollToId(id, scrollOffset)
  }

  return (
    <Grid>
      <Row>
        <TopStickyWrapper as={Col} xs={12} sm={2}>
          <DecadeTimeLine
            ref={decadeRef}
            years={props.items.map(item => item.year)}
            selectedYear={currentYear}
            onDecadeClick={handleDecadeClick}
          />
        </TopStickyWrapper>
        <Col xs={12} sm={10}>
          <Separator />
          {props.items.map((item, index) => (
            <TimelineEvent
              key={index}
              id={getTimelineEventId(item.year)}
              card={{
                imgPosition: index % 2 ? 'right' : 'left',
                imgixSrc: item.imgixSrc,
                imgDescription: item.imgDescription,
                title: item.title,
                contentHTML: item.contentHTML
              }}
              line={{
                year: item.year,
                showTimelineLine: index !== props.items.length - 1,
                onVisibilityChange: handleItemVisibilityChange(item.year),
                highlight: item.year === currentYear
              }}
            />
          ))}
        </Col>
      </Row>
    </Grid>
  )
}

const getTimelineEventId = (year: number) => `TimelineEvent-${year}`

type useYearVisibilityHook = [
  number,
  (year: number) => void,
  (year: number) => (inView: boolean) => void,
  React.MutableRefObject<DecadeTimeLineRef>
]
function useYearVisibility(props: CompleteTimeLineProps): useYearVisibilityHook {
  const [currentYear, forceCurrentYear] = React.useState<number>(props.items[0].year)
  const visibilityDic = React.useRef(
    props.items.reduce((acc, curr) => {
      acc[curr.year] = false
      return acc
    }, {})
  )

  const decadeTimelineRef = React.useRef<DecadeTimeLineRef>()
  const setCurrentYear = (year: number) => {
    let nextCurrentYear = year

    const firstYear = props.items[0].year
    const firstVisible = visibilityDic.current[firstYear]
    if (firstVisible) nextCurrentYear = firstYear

    const lastYear = props.items[props.items.length - 1].year
    const lastVisible = visibilityDic.current[lastYear]
    if (lastVisible) nextCurrentYear = lastYear

    forceCurrentYear(nextCurrentYear)
    /** setTimeout to garantee that all styles (which may change some sizing) are applied before scrolling  */
    setTimeout(() => decadeTimelineRef.current.scrollToDecade(year), 0)
  }

  const debounceSetCurrentYear = React.useRef(debounce(setCurrentYear, 300))

  const handleItemVisibilityChange = (year: number) => (inView: boolean) => {
    visibilityDic.current[year] = inView
    if (inView) {
      debounceSetCurrentYear.current(year)
    }
  }

  return [currentYear, forceCurrentYear, handleItemVisibilityChange, decadeTimelineRef]
}

export default CompleteTimeLine

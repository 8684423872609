import { Color, Spacing, TransitionDuration } from 'atomic'
import { onlyDesktopStyle } from 'atomic/legacy/obj.mixin'
import styled from 'styled-components'

export const TimelineEventStyled = styled.div`
  position: relative;
  display: grid;
  grid-gap: ${Spacing.XXLarge};
  grid-template-columns: [line] auto [year] auto [card] 1fr;
  @media all and (max-width: 48em) {
    grid-gap: ${Spacing.Large};
    grid-template-columns: auto;
  }
`

export const TimelineEventLineWrapperStyled = styled.div`
  position: relative;
  ${onlyDesktopStyle}
`
export const TimelineEventLineStyled = styled.div`
  height: 100%;
  width: 2px;
  background: ${props => props.theme.color.primary};
`
export interface TimelineEventBallStyledProps {
  highlight: boolean
}
const ballSize = 11
export const TimelineEventBallStyled = styled.div<TimelineEventBallStyledProps>`
  position: absolute;
  top: 0;
  left: ${`-${ballSize / 2 - 1}px`};
  width: ${`${ballSize}px`};
  height: ${`${ballSize}px`};
  transform: scale(${(props: TimelineEventBallStyledProps) => (props.highlight ? 1.6 : 1)});
  border-radius: ${`${ballSize}px`};
  border: ${props => `2px solid ${props.theme.color.primary}`};
  background: ${props => (props.highlight ? props.theme.color.primary : Color.White)};
  transition: transform ${TransitionDuration};
`

export const TimelineEventYearStyled = styled.div``
export const TimelineEventCardWrapperStyled = styled.div`
  margin-bottom: ${Spacing.XXLarge};
`

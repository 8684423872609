import * as React from 'react'
import { ImageWithFoldStyled } from './image-with-fold.style'
import { LazyLoadImage } from 'atomic/legacy/atm.lazy-load-image'

export type FoldPosition = 'top-left' | 'top-right'

export interface ImageWithFoldProps {
  imgixSrc: string
  imgAlt: string
  imgAspectRatio: number
  foldPosition: FoldPosition
}

const ImageWithFold: React.FunctionComponent<ImageWithFoldProps> = props => (
  <ImageWithFoldStyled foldPosition={props.foldPosition}>
    <LazyLoadImage
      src={props.imgixSrc}
      alt={props.imgAlt}
      aspectRatio={props.imgAspectRatio}
      fitWidth
    />
  </ImageWithFoldStyled>
)

export default ImageWithFold

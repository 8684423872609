import { Color, Spacing } from 'atomic'
import styled from 'styled-components'
import { ImgPosition } from './timeline-event-card.component'

export const TimelineEventCardStyled = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: [col-1-start] repeat(2, 1fr) [col-3-start] 1fr [col-4-start] repeat(3, 1fr) [col-7-start] 1fr [col-end];
  grid-template-rows: [row-1-start] 32px [row-2-start] auto [row-3-start] auto [row-end];
  @media all and (max-width: 48em) {
    grid-template-columns: [col-1-start] 1fr;
    grid-template-rows: [row-1-start] auto [row-2-start] auto;
  }
`

export const TimelineEventCardTextWrapper = styled.div<{ imgPosition: ImgPosition }>`
  background-color: ${Color.GrayLight};
  padding: ${Spacing.Large} ${Spacing.XLarge};
  grid-row-start: 2;
  grid-column-start: ${props => (props.imgPosition === 'left' ? 4 : 1)};
  grid-column-end: span ${props => (props.imgPosition === 'left' ? 4 : 3)};
  @media all and (max-width: 48em) {
    grid-row-start: row-2-start;
    grid-row-end: span 1;
    grid-column-start: col-1-start;
    grid-column-end: span 1;
  }
`

export const TimelineEventCardTextSeparator = styled.div`
  margin-top: ${Spacing.XLarge};
`

export const TimelineEventCardImageWrapper = styled.div<{ imgPosition: ImgPosition }>`
  grid-row-start: row-1-start;
  grid-row-end: row-end;
  grid-column-start: ${props => (props.imgPosition === 'left' ? `col-1-start` : `col-4-start`)};
  grid-column-end: span ${props => (props.imgPosition === 'left' ? 3 : 4)};

  @media all and (max-width: 48em) {
    grid-row-start: row-1-start;
    grid-row-end: span 1;
    grid-column-start: col-1-start;
    grid-column-end: span 1;
  }
`
export const TimelineEventCardSpacer = styled.div<{ imgPosition: ImgPosition }>`
  background-color: ${Color.GrayLight};
  grid-row-start: row-2-start;
  grid-column-start: ${props => (props.imgPosition === 'left' ? `col-3-start` : `col-4-start`)};
  grid-column-end: span 1;
`

import { H2, HeaderHeight } from 'atomic'
import { useClientRectByPassingRef } from 'atomic/obj.custom-hooks/client-rect.hook'
import { hasWindow, isDesktopScreenSize } from 'utils/browser'
import * as React from 'react'
import Observer from 'react-intersection-observer'
import TimelineEventCard, { TimelineEventCardProps } from './timeline-event-card.component'
import {
  TimelineEventBallStyled,
  TimelineEventCardWrapperStyled,
  TimelineEventLineStyled,
  TimelineEventLineWrapperStyled,
  TimelineEventStyled,
  TimelineEventYearStyled
} from './timeline-event.style'

interface TimelineEventProps {
  id?: string
  card: TimelineEventCardProps
  line: {
    year: number
    showTimelineLine: boolean
    onVisibilityChange: (inView: boolean) => void
    highlight: boolean
  }
}
const arePropsEqual = (prevProps: TimelineEventProps, nextProps: TimelineEventProps) => {
  if (prevProps.line.highlight !== nextProps.line.highlight) {
    return false
  }

  const prevCard = prevProps.card
  const prevLine = prevProps.line
  const nextCard = prevProps.card
  const nextLine = prevProps.line
  return (
    prevCard.imgixSrc === nextCard.imgixSrc &&
    prevCard.imgDescription === nextCard.imgDescription &&
    prevCard.imgPosition === nextCard.imgPosition &&
    prevCard.title === nextCard.title &&
    prevCard.contentHTML === nextCard.contentHTML &&
    prevLine.year === nextLine.year && prevLine.showTimelineLine === nextLine.showTimelineLine
  )
}

const TimelineEvent: React.FunctionComponent<TimelineEventProps> = React.memo(props => {
  const handleVisibilityChange = React.useCallback(
    (inView: boolean, _entry: IntersectionObserverEntry) => {
      props.line.onVisibilityChange(inView)
    },
    [props.line.onVisibilityChange]
  )

  const line = React.useMemo(
    () => (
      <TimelineEventLineWrapperStyled>
        <TimelineEventBallStyled highlight={props.line.highlight} />
        {props.line.showTimelineLine && <TimelineEventLineStyled />}
      </TimelineEventLineWrapperStyled>
    ),
    [props.line.highlight, props.line.showTimelineLine]
  )
  const year = React.useMemo(
    () => (
      <TimelineEventYearStyled>
        <H2 cell>{props.line.year}</H2>
      </TimelineEventYearStyled>
    ),
    [props.line.year]
  )
  const card = React.useMemo(
    () => (
      <TimelineEventCardWrapperStyled>
        <TimelineEventCard {...props.card} />
      </TimelineEventCardWrapperStyled>
    ),
    [
      props.card.imgixSrc,
      props.card.imgDescription,
      props.card.imgPosition,
      props.card.title,
      props.card.contentHTML
    ]
  )

  const wrapperRef = React.useRef()
  const wrapperRect = useClientRectByPassingRef(wrapperRef)
  const observerThreshold = React.useMemo(() => {
    const windowHeight = hasWindow() ? window.innerHeight : 0
    // this constant improve observer detection by reducing the considered window height
    const windowHeightFix = isDesktopScreenSize() ? HeaderHeight.Desk : HeaderHeight.Mobile * 5
    const fixedWindowHeight = Math.max(windowHeight - windowHeightFix, 0)
    const wrapperHeight = wrapperRect ? wrapperRect.height : 0
    return fixedWindowHeight >= wrapperHeight ? 1 : fixedWindowHeight / wrapperRect.height
  }, [wrapperRect && wrapperRect.height, hasWindow() && window.innerHeight])

  return (
    <Observer threshold={observerThreshold} onChange={handleVisibilityChange} id={props.id}>
      <TimelineEventStyled ref={wrapperRef}>
        {line}
        {year}
        {card}
      </TimelineEventStyled>
    </Observer>
  )
}, arePropsEqual)

export default TimelineEvent
